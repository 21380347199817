/* eslint-disable no-irregular-whitespace */
import React from 'react'
import { Link, graphql, useStaticQuery } from 'gatsby'
import { Box, Card, Heading, Link as RebassLink, Text } from 'rebass/styled-components'
import { StaticImage } from 'gatsby-plugin-image'

import { Layout } from '../../components/layout'
import { SEO } from '../../components/seo'
import { Hero } from '../../components/hero'
import { ContentSection } from '../../sections/content'
import reliefBg from '../../images/relief-bg.png'

const VzorovaniPage = () => {
  const data = useStaticQuery(graphql`
    {
      heroBg: file(relativePath: { regex: "/nabizime-hero-bg.jpg/" }) {
        childImageSharp {
          gatsbyImageData(quality: 90)
        }
      }
    }
  `)
  const heroBg = data.heroBg.childImageSharp.gatsbyImageData.images.fallback.src

  return (
    <Layout>
      <SEO
        title="Vzorování na míru"
        description="když se sny zákazníků začnou plnit"
      />

      <Hero
        image={heroBg}
        bgPosition="center 60%"
        title="Vzorování na míru"
        text="když se sny zákazníků začnou plnit"
        leadText
      />

      <ContentSection
        sx={{
          backgroundImage: `linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 100%), url(${reliefBg})`,
          backgroundSize: '100%, 50%',
        }}
      >
        <Box sx={{ position: 'relative', mt: -6 }}>
          <Card
            sx={{
              gridTemplateColumns: ['1fr', 'auto 1fr'],
              gap: 4,
              alignItems: 'center',
            }}
          >
            <StaticImage
              src="../../images/frypo-logo.png"
              alt=""
              placeholder="none"
              quality={90}
              height={89}
              objectFit="contain"
              objectPosition="0"
            />

            <Text as="p" sx={{ fontSize: '1.125rem', fontWeight: 'bold' }}>
              Jedinečnou a originální krásu přírodního dřeva lze ještě umocnit
              vhodnou povrchovou úpravou. Díky ní se vykreslí struktura,
              letorosty a zdůrazní se (nebo se naopak potlačí) celkový vzhled
              dřeva.
            </Text>
          </Card>
        </Box>
      </ContentSection>

      <ContentSection sx={{ pt: 0 }}>
        <Heading variant="contentSectionTitle" as="h2">
          Od lakování po moření a tónování
        </Heading>

        <Text as="p">
          Možností povrchové úpravy dřeva je dnes celá řada. Aktuálním trendem
          je takzvaný ultra matt, povrch, na kterém vrstva laku není vidět, a
          výsledek tak připomíná surové dřevo. Na opačném pólu stojí klasické
          úpravy, jejichž výsledkem je hladký povrch s viditelným nánosem laku.
          Mezi tím jsou pak nejrůznější olejované povrchy nebo povrchy
          voskované, jež se vyznačují sametovým vzhledem.
        </Text>
      </ContentSection>

      <ContentSection dark>
        <Text as="p">
          Povrch dřeva lze rovněž tónovat mořidly. Po této úpravě je vidět
          struktura dřeva, povrch však již nemá přírodní odstín. Nebo lze použít
          takový druh moření, jímž lze docílit toho, že strukturu dřeva nelze
          vidět vůbec a povrch je zcela zalakovaný.
        </Text>
      </ContentSection>

      <ContentSection>
        <Text as="p">
          Zcela unikátní variantu tónování dřevěných povrchů pak představuje
          takzvané vzorování na míru. Jedná se o aplikaci různých odstínů,
          dekorů nebo vzorů na surový dřevěný povrch. Používají se přitom
          rozličné patiny, patinovací mořidla či fládry. Díky kombinaci našich
          znalostí, zkušeností a kvalitních materiálů jsme schopni prakticky
          každou představu zákazníka proměnit ve skutečnost.
        </Text>

        <RebassLink variant="linkButton" as={Link} to="/kontakt">
          Splnit si sen
        </RebassLink>
      </ContentSection>
    </Layout>
  )
}

export default VzorovaniPage
